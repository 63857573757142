/* Style overrides for react-joyride.
   Would like to find a cleaner way to do this, but for now this works.
*/

.joyride-tooltip__header {
    border-bottom-color: green;
}

.joyride-tooltip__button {
    background-color: green;
}

.joyride-tooltip__close {
    display: none;
}

.joyride-overlay {
    cursor: not-allowed !important;
}

.joyride-tooltip__button--secondary {
    color: green;
    background-color: transparent;
}

.joyride-tooltip__button--primary:active, .joyride-tooltip__button--primary:focus, .joyride-tooltip__button--primary:hover {
    background-color: green;
    color: #fff;
}
