body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

pre {
  background: rgb(250, 250, 250);
  padding-left: 5px;
  padding-right: 5px;
  border-style:solid;
  border-color: #BDBDBD;
}
pre > code {
  font-family: "Sans Mono", "Consolas", "Courier", monospace;
  -webkit-user-select: none;
          user-select: none;
}

li {
  line-height: 1.4
}

.ace_gutter-cell.ace_breakpoint{
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAACZklEQVR42p1Tz08TQRT+Zn90gV1aS4lCJD0gHkRD2hAiojEGayJIYi9GLxgPHj0QL/wJxovx6tmDMR7UmwcjMdEWI0mbBomoAUWxiC2Vdrvd7q9xutsWCid9ydt5M9/7Xt7s+4ZgjxUvTCT4gd4hmCZAyA7A87CXv2f8cy/HdueTPeSUGD8ZgbmNlgKUgvIc6OYfmK9X0geSb6L7ChRiE6m2yyMRWvgBR9UYwtXJTn1lLvFA2YL5bj0dTHhF3AL52MVU+xQj59ZAq1WAY2SHeiTv43bhusCwigV9IZvuTr6Nks3xWFK5NDzqZL8AagVokwDD8IiCz70G1TQQUWT/gXm1AsKKOLYALZOfJ1s3pw2R3xbxH1b9um2S3LW4Gnr0VK4dZJ88Q++VeDOhtlfOnkHnoW6UfuVQmDqH8PvFJr4xdqpMspPnVXrjllwjrndJLnB4q4q1kRNuXCOUBoPoXCq4Z7X91swsuu7fxerA0TJZPdavduR/yvztOwjNzjSTdhdQ+/1QVopNrGHLPqFMPvYdVP33HsiN1jPHBzH0YQmfJW/CPWu/911B7QvAFiVslIwy+XQkbChBWQyyhH+1laJukleBYDLao4z6CnmYvADKRsfZtjtzhxPAWRaoaXgjZOLiHAccizW2Lha1ebfPF0ogNRySIxITkUXrAqrrx11JXUhMlAKLdZtioaynJ0092pTyc9mfOh2oFdFhMBWSFhF6gchOKwxLapV03DZ2pNywxx1KalyWIwrrxKZ0pwPm7BWg5NiY0/X0VWrtf0wNeyi1J8KUDtmO0wLzrMg3x85cp1bLc/4L+kogVvCS1pkAAAAASUVORK5CYII=);
  margin-left: 4px;
}

.debug_pointer {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAACN0lEQVR42pWTy2tTURDGv5NUpUlNaqpBSrFKsVSpgohVSdGiIOKiIJqNxWUR94Ir/wHRhbjpQqGoi9RGKS5EQWMXUjHiRlzZRE3bvOQ2ktz3I3eceysi1aZ6YJhzuPP95szHuQItVv/1T7Twvgxj6rhYq0a0AhzMGBQpS3g9k4c9/XdIS0DimUw3T3XgaqqEuZkc7Id/QloCuiclejzWhTauujJVwpsnOZirxhF7H9SoezNgWQQiDpeYSrAMF21BwmAijkQYiIYCuJYuYf55Hnrq2C+IuPCiTpMnI6jyoekFAbbLH7ikZgF38g6W1Cb2hQnbY5tw+1ERtWwB8v1hHyKOpJdp/HQMkkzQHb5J08suDBscBMVuwnEIRYUQ4BZDvSFkni7gy7sSVIaIA/eqdHE0Dqnu+gLdDxeqSTCbXvDeIBgc5YaDzmgQZ3YGMTdbwYdsBWL/xCIlz/VA+u5AM13/FprFmb3QWaTqvLc8oOuDKyUTew51YtjVkU7lIAZufabR5C4sf7Mhczedu2psqAfTNBby/BqPoLCpimzB7WhHvLAIp6yidveoELtv5OjEWB9qNaDBRTpfXdUcFjT97ppiQeazrdsIxNoR+lhAWLNRnTi8YmLXpSzJuuU/CMHWe+6v7AF34wbog73spoHIjiic7FdEVBOVn+J1HxLOZkiMDGBrTwjy2yVsaago/yb+J0Df5SEUXxYQqysorRKvDzj/isL926DOS8D0yP//TEjOkp/XEHvrB9NnO4/EkzmNAAAAAElFTkSuQmCC);
  background-position: 2px 2px;
}

.invalid-breakpoint {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsIAAA7CARUoSoAAAAKASURBVDhPZVM9T2JRED3vPXjwAJUECows2xjLFSlg3VhYmQ0VVvbEn2AidFtb2fojtLQysTEIiQayZGk1Gj8wUT4EnqDCnrk8zeqeZGScuXPe3DNzNXxCPp8vzMzMJF5fX0fEUP5IXNd1XF1d/d7a2vqhDjr4QMDiciqVit/e3joRhb7L5fLQMBgMUKvVKtvb2wtODobzi1wup4pbrZY6SGMTCubz8zOGwyEMw0AwGIzMzc1lisXijtQpgs3NzXIymYw3Gg08PT2J2S8vLyaLDRoc6/CoKZ1MTU0pklKptKNtbGwcs/j7/f09er2e8NmapllS9C/k64y1mZsUEuno/Py8qEcikeTFxQU6nY4EbR60stkspqennVIgHA5jfX0dHo9nkpq2JWZZFqLRaEpnQExi0qIl9z08PEQmk0EgEIDP58Pq6iqOjo7Q7/flnJD02AncbrdmrKys/GJQWL00pcnDwwOkzaWlJczOzuLs7AwnJyeSUmBOo+mil+7EBjT32B2DKssXRHX19U9wsYs/4rwRhGn22B0jkUhArtbtdrG4uOhE3zHkVb+KozebTZ4bydwtlSJisRi4E9jb28Pu7i7m5+fBsTlZgDW9y8vLAKcwNEzT/EmCqIxRhJmYmMDa2hr29/dRr9chonKFkU6nUa1WRR+bU/Pf3d2JNiW1ysvLy2Xuf1yE41hB/03xd3CEQijFloycfqVQKCwo1dnKDsXK+P3+iNzZtm14vd42CT2S5wJ12fLo+vra+/j4iJubmwpFVu/hw2OiWGUuR1w0IQFCoZCKy/VkZAK2XTk9PX1/TP89Z651gcXfuECm/E9d2iScFJ/vo35wcPBF/DGAv1pNUXRGWpf3AAAAAElFTkSuQmCC);
  background-position: 2px 2px;
}

.ace_step {
  background: none !important;
  outline: 2px solid red;
  outline-offset: -2px;
}

@keyframes full-fade {
  0% {
		opacity: 1.0;
	}
	100% {
		opacity: 0.0;
	}
}

.dbg_runline {
  background: linear-gradient(to right, rgb(62, 231, 39), white);
  position: absolute;
  animation-name: full-fade;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.ace-syntaxerr {
  position:absolute;
  background:rgba(255,255,100,0.3);
  z-index:20
}

.ace-warning {
  background: rgba(255, 115, 0, 0.5);
  position: absolute;
}

.Resizer {
  background: #000;
  opacity: .2;
  z-index: 1;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
cursor: not-allowed;
}
.Resizer.disabled:hover {
border-color: transparent;
}

@keyframes emphasize-animation-flash {
  0%   {background-color: yellow; }
  33%  {transform:scale(1.5); }
  66%  {transform:scale(1.0);}
  100% {background-color: white;}
}

@keyframes emphasize-animation-shake {
  0%   {transform:scale(1.4);}
  20%  {transform:rotate(10deg); }
  40%  {transform:rotate(-7deg); }
  60%  {transform:rotate(5deg); }
  80%  {transform:rotate(0deg); }
  100% {transform:rotate(0deg); transform:scale(0); }
}

video#bgvid {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -100;
  background-color: #222;
}

@keyframes motionArrow {
  from { transform: translateY(0px) }
  to   { transform: translateY(10px) }
}
.scroll-down-arrow:hover {
  animation: motionArrow 1s ease infinite alternate;
}

#GoogAvatar_button:hover {
  background-color: rgba(192, 192, 192, 0.5);
}

.katex > .katex-html {
	white-space: normal;
}

/*
Firia Labs - map ACE to highlight.js syntax highlighting
Cherry-picking a few from the ACE "chrome" theme at codespace\node_modules\brace\theme\chrome.js
TODO: add remaining theme mappings!

Based on Atom One Light by Daniel Gamage
Original One Light Syntax theme from https://github.com/atom/one-light-syntax

base:    #fafafa
mono-1:  #383a42
mono-2:  #686b77
mono-3:  #a0a1a7
hue-1:   #0184bb
hue-2:   #4078f2
hue-3:   #a626a4
hue-4:   #50a14f
hue-5:   #e45649
hue-5-2: #c91243
hue-6:   #986801
hue-6-2: #c18401

*/

.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  color: #383a42;
  background: #fafafa;
}

.hljs-comment,
.hljs-quote {
  color: #236e24;
  font-style: italic;
}

.hljs-doctag,
.hljs-keyword,
.hljs-formula {
  color: #a626a4;
}

.hljs-section,
.hljs-name,
.hljs-selector-tag,
.hljs-deletion,
.hljs-subst {
  color: #e45649;
}

.hljs-string,
.hljs-literal {
  color: #0000cd;
}

.hljs-regexp,
.hljs-addition,
.hljs-attribute,
.hljs-meta-string {
  color: #50a14f;
}

.hljs-built_in,
.hljs-class
.hljs-title {
  color: #000000;
}

.hljs-attr,
.hljs-variable,
.hljs-template-variable,
.hljs-type,
.hljs-selector-class,
.hljs-selector-attr,
.hljs-selector-pseudo,
.hljs-number {
  color: #0000cd;
}

.hljs-symbol,
.hljs-bullet,
.hljs-link,
.hljs-meta,
.hljs-selector-id {
  color: #4078f2;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

.hljs-link {
  text-decoration: underline;
}

/* Styles and Animations for lessons */

hr {
    border-style: groove;
}

mark {
    cursor: help;
    background-color: transparent;
    color: #0645ad;
    padding-left: 1.25em;
    background-image: url(/static/media/build_icon.3612a3e5.svg);
    background-repeat: no-repeat;
    background-position: left;
    background-size: auto 1em;
}
mark:hover {
    text-decoration: underline;
}
mark > span {
    border-bottom-color: rgba(0, 0, 255, 0.048) !important;
    border-bottom-style: dashed !important;
}

.centered {
    text-align: center;
}

.just-right {
    text-align: right;
}

.inline {
    display: inline;
}

.margin-none {
    margin: 0px;
}

.lesson-title {
    text-align: center;
    margin-top: 0.1em;
    margin-bottom: 0.1em;
}

.flyRightOnHover {
    transition: transform 2s;
}
.flyRightOnHover:hover {
    transform: translate(200px);
}

@keyframes codebot-dance1 {
    0%   {left:-30px; top:15px;}
    25% {left:0px; top:0px;}
    50% {left:30px; top:15px;}
    75% {left:0px; top:0px;}
    100% {left:-30px; top:15px;}
}

@keyframes pulse-scale {
    0%   {transform: scale(1.25);}
    100%   {transform: scale(1.0);}
}

@keyframes slide-right {
    0%   {}
    100%   {margin: 10px; float: right; width: 50px;}
}

@keyframes shiny {
    from { -webkit-filter: drop-shadow(1px 1px 3px #333); filter: drop-shadow(1px 1px 3px #333) }
    to   { -webkit-filter: drop-shadow(1px 1px 3px #FFF); filter: drop-shadow(1px 1px 3px #FFF) }
  }

/* Note: This animation uses about 10% CPU on my system */
.shiny {
    animation: 2s linear infinite alternate shiny;
    margin: 5px;
  }

table.table-striped  {
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #000;
    margin-left: 5%;
}
.table-striped tr:nth-child(even) {
    background-color: #ffffff;
}
.table-striped th {
    background-color: darkseagreen;
    padding: 4px;
    padding-right: 10px;
    padding-left: 10px;
}
.table-striped td {
    border-collapse: collapse;
    border: 1px solid black;
    text-align: left;
    padding: 4px;
}

/* Style overrides for react-joyride.
   Would like to find a cleaner way to do this, but for now this works.
*/

.joyride-tooltip__header {
    border-bottom-color: green;
}

.joyride-tooltip__button {
    background-color: green;
}

.joyride-tooltip__close {
    display: none;
}

.joyride-overlay {
    cursor: not-allowed !important;
}

.joyride-tooltip__button--secondary {
    color: green;
    background-color: transparent;
}

.joyride-tooltip__button--primary:active, .joyride-tooltip__button--primary:focus, .joyride-tooltip__button--primary:hover {
    background-color: green;
    color: #fff;
}

