body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

pre {
  background: rgb(250, 250, 250);
  padding-left: 5px;
  padding-right: 5px;
  border-style:solid;
  border-color: #BDBDBD;
}
pre > code {
  font-family: "Sans Mono", "Consolas", "Courier", monospace;
  user-select: none;
}

li {
  line-height: 1.4
}

.ace_gutter-cell.ace_breakpoint{
  background-image: url('StopSign.png');
  margin-left: 4px;
}

.debug_pointer {
  background-image: url('Forward.png');
  background-position: 2px 2px;
}

.invalid-breakpoint {
  background-image: url('assets/InvalidBreakpoint.png');
  background-position: 2px 2px;
}

.ace_step {
  background: none !important;
  outline: 2px solid red;
  outline-offset: -2px;
}

@keyframes full-fade {
  0% {
		opacity: 1.0;
	}
	100% {
		opacity: 0.0;
	}
}

.dbg_runline {
  background: linear-gradient(to right, rgb(62, 231, 39), white);
  position: absolute;
  animation-name: full-fade;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.ace-syntaxerr {
  position:absolute;
  background:rgba(255,255,100,0.3);
  z-index:20
}

.ace-warning {
  background: rgba(255, 115, 0, 0.5);
  position: absolute;
}

.Resizer {
  background: #000;
  opacity: .2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
cursor: not-allowed;
}
.Resizer.disabled:hover {
border-color: transparent;
}

@keyframes emphasize-animation-flash {
  0%   {background-color: yellow; }
  33%  {transform:scale(1.5); }
  66%  {transform:scale(1.0);}
  100% {background-color: white;}
}

@keyframes emphasize-animation-shake {
  0%   {transform:scale(1.4);}
  20%  {transform:rotate(10deg); }
  40%  {transform:rotate(-7deg); }
  60%  {transform:rotate(5deg); }
  80%  {transform:rotate(0deg); }
  100% {transform:rotate(0deg); transform:scale(0); }
}

video#bgvid {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -100;
  background-color: #222;
}

@keyframes motionArrow {
  from { transform: translateY(0px) }
  to   { transform: translateY(10px) }
}
.scroll-down-arrow:hover {
  animation: motionArrow 1s ease infinite alternate;
}

#GoogAvatar_button:hover {
  background-color: rgba(192, 192, 192, 0.5);
}

.katex > .katex-html {
	white-space: normal;
}
