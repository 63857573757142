/* Styles and Animations for lessons */

hr {
    border-style: groove;
}

mark {
    cursor: help;
    background-color: transparent;
    color: #0645ad;
    padding-left: 1.25em;
    background-image: url('./StartCoding/assets/build_icon.svg');
    background-repeat: no-repeat;
    background-position: left;
    background-size: auto 1em;
}
mark:hover {
    text-decoration: underline;
}
mark > span {
    border-bottom-color: rgba(0, 0, 255, 0.048) !important;
    border-bottom-style: dashed !important;
}

.centered {
    text-align: center;
}

.just-right {
    text-align: right;
}

.inline {
    display: inline;
}

.margin-none {
    margin: 0px;
}

.lesson-title {
    text-align: center;
    margin-top: 0.1em;
    margin-bottom: 0.1em;
}

.flyRightOnHover {
    transition: transform 2s;
}
.flyRightOnHover:hover {
    transform: translate(200px);
}

@keyframes codebot-dance1 {
    0%   {left:-30px; top:15px;}
    25% {left:0px; top:0px;}
    50% {left:30px; top:15px;}
    75% {left:0px; top:0px;}
    100% {left:-30px; top:15px;}
}

@keyframes pulse-scale {
    0%   {transform: scale(1.25);}
    100%   {transform: scale(1.0);}
}

@keyframes slide-right {
    0%   {}
    100%   {margin: 10px; float: right; width: 50px;}
}

@keyframes shiny {
    from { filter: drop-shadow(1px 1px 3px #333) }
    to   { filter: drop-shadow(1px 1px 3px #FFF) }
  }

/* Note: This animation uses about 10% CPU on my system */
.shiny {
    animation: 2s linear infinite alternate shiny;
    margin: 5px;
  }

table.table-striped  {
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #000;
    margin-left: 5%;
}
.table-striped tr:nth-child(even) {
    background-color: #ffffff;
}
.table-striped th {
    background-color: darkseagreen;
    padding: 4px;
    padding-right: 10px;
    padding-left: 10px;
}
.table-striped td {
    border-collapse: collapse;
    border: 1px solid black;
    text-align: left;
    padding: 4px;
}
